import React, { useState } from "react";
import Stars from "./stars";
const ChamberReviews = () => {
  const [isMore, setMore] = useState(false);
  const handleClick = () => {
    return setMore(!isMore);
  };

  const reviewsChamb = [
    {
      name: "Janice G.",
      date: "9/29/2023",
      stars: 5.0,
      text: "Leo and his crew were very professional and did great work. They went above and beyond to make my vision for my yard come to life. Easy to work with and left my yard with no trash or materials. They were extremely efficient and finished the job quickly and beautifully. Leo made sure I was satisfied with the project before he left. I highly recommend LA Landscaping.",
    },
    {
      name: "Susan J.",
      date: "9/23/2023",
      stars: 5.0,
      text: "Responsive",
    },
    {
      name: "Gary L.",
      date: "9/19/2023",
      stars: 5.0,
      text: "This crew was extremely efficient and professional, cleaned up, right on time, and well worth the money would highly recommend",
    },
    {
      name: "Chris D.",
      date: "9/18/2023",
      stars: 5.0,
      text: "Very responsive. The guy was very nice and made sure I was satisfied with the work.",
    },
    {
      name: "Collena ann N.",
      date: "9/16/2023",
      stars: 4.5,
      text: "They responded quickly, they were efficient, hard working, great customer service, good work, they were reasonable. Definitely recommend them! Collena N",
    },
    {
      name: "John A.",
      date: "9/13/2023",
      stars: 5.0,
      text: "Really nice, easy to deal with, no surprises. Did exactly what they said and the price was great.",
    },
    {
      name: "Greg W.",
      date: "9/9/2023",
      stars: 5.0,
      text: "They were prompt and very hard working. They completed the work on time. Leonardo suggested some things and I'm so glad I took his advice. After the cleanup was finished",
    },
    {
      name: "Marilyn R.",
      date: "9/7/2023",
      stars: 5.0,
      text: "Leonardo was prompt, courteous and efficient. Definitely use them again and refer them friends",
    },
    {
      name: "Marilyn R.",
      date: "9/7/2023",
      stars: 4.5,
      text: "Leonardo was prompt, courteous and efficient. Very satisfied and I definitely will use them again.",
    },
    {
      name: "Carlyn J.",
      date: "9/7/2023",
      stars: 5.0,
      text: "Mike and his crew removed my old shrubs in the front of the house and replaced them with nice low maintenance shrubs, placed stonework all the way across, and put down river rock. They trimmed the maple tree also. They buried my gutter to drain underground. I had a small brush pile behind my shed that they hauled off for me. They finished all of the work in 1 1/2 days! They were friendly, hard workers! They did a very professional job! I am very pleased with their service!!",
    },
    {
      name: "Jack S.",
      date: "9/7/2023",
      stars: 5.0,
      text: "They did amazing work and clean up!",
    },
    {
      name: "Chalia W.",
      date: "9/1/2023",
      stars: 4.5,
      text: "Leo and his team were great, did an exceptional job. Would definitely work with them again.",
    },
    {
      name: "Smitherman M.",
      date: "8/31/2023",
      stars: 5.0,
      text: "Prompt arrival, task done quickly and well, friendly service.",
    },
    {
      name: "Roger B.",
      date: "8/23/2023",
      stars: 5.0,
      text: "Leo is fantastic. Very fair, and did absolutely everything I asked and cleaned up well afterwards."
    },
    {
      name: "Anthony R.",
      date: "8/13/2023",
      stars: 5.0,
      text: "They were on time. And do a great job.",
    },
    {
      name: "Steve L.",
      date: "7/22/2023",
      stars: 5.0,
      text: "They did a great job. I will hire them again.",
    },
    {
      name: "Jefrae A.",
      date: "7/16/2023",
      stars: 5.0,
      text: "Leo and company came when they said they would, They completed the job right on time. Leo is a gentleman. He listened to me and made suggestions that I had not thought of. I thought their bid was fair and when they were completed they cleaned up and left me with a very nice rock job. I would hire him again!",
    },
    {
      name: "Richard P.",
      date: "6/22/2023",
      stars: 5.0,
      text: "Leo is a good man and hard worker. He came and got the job done, working in the evening, after-hours. Very happy with the results! Thanks Leo (Maria, and Leonardo)",
    },
    {
      name: "Maxine G.",
      date: "6/19/2023",
      stars: 5.0,
      text: "These workers were very professional and got the job done quickly and expertly. Leo listened to what I wanted and followed through with my requests and a few good suggestions. I am pleased with the job.",
    },
    {
      name: "Jeff U.",
      date: "6/13/2023",
      stars: 5.0,
      text: "Quick, quality work with sodding a small, irregularly shaped backyard. Understood what a complete job looked like without having to explain every little corner and detail. Would hire again.",
    },
    {
      name: "Ben A.",
      date: "5/30/2023",
      stars: 5.0,
      text: "Prompt and hard workers not quitting before the job was done.",
    },
    {
      name: "Michael P.",
      date: "5/29/2023",
      stars: 5.0,
      text: "Leo and his team were great to work with. They do quality work and have pride in what they do. Completed the project timely and even helped us with a couple issues not originally on the list. Highly recommend and would definitely use them again.",
    },
    {
      name: "Otis D.",
      date: "5/16/2023",
      stars: 5.0,
      text: "Leo listened to my concerns and put me at ease that it would be completed completely.",
    },
    {
      name: "Jim M.",
      date: "5/3/2023",
      stars: 5.0,
      text: "Leo and his team did a great job, respectful of our property and did a great job of cleaning up when finished. I would highly recommend them and would use his services again.",
    },
    {
      name: "Casey H.",
      date: "5/3/2023",
      stars: 5.0,
      text: "Leo and his crew did a great job with our sod installation project. Very professional and hauled off all the old/dead grass.",
    },
    {
      name: "Kathleen W.",
      date: "4/30/2023",
      stars: 5.0,
      text: "Excellent",
    },
    {
      name: "Christopher L.",
      date: "4/22/2023",
      stars: 5.0,
      text: "Communication was excellent. Price - excellent. Work ethic - Leo and team works extremely hard and are skilled. They were very good at cleanup.",
    },
    {
      name: "Jeff R.",
      date: "3/31/2023",
      stars: 5.0,
      text: "It was great!",
    },
    {
      name: "Jeff U.",
      date: "6/13/2023",
      stars: 5.0,
      text: "Quick, quality work with sodding a small, irregularly shaped backyard. Understood what a complete job looked like without having to explain every little corner and detail. Would hire again.",
    },
    {
      name: "Ben A.",
      date: "5/30/2023",
      stars: 5.0,
      text: "Prompt and hard workers not quitting before the job was done.",
    },
    {
      name: "Michael P.",
      date: "5/29/2023",
      stars: 5.0,
      text: "Leo and his team were great to work with. They do quality work and have pride in what they do. Completed the project timely and even helped us with a couple issues not originally on the list. Highly recommend and would definitely use them again.",
    },
    {
      name: "Otis D.",
      date: "5/16/2023",
      stars: 5.0,
      text: "Leo listened to my concerns and put me at ease that it would be completed completely.",
    },
    {
      name: "Jim M.",
      date: "5/3/2023",
      stars: 5.0,
      text: "Leo and his team did a great job, respectful of our property and did a great job of cleaning up when finished. I would highly recommend them and would use his services again.",
    },
    {
      name: "Casey H.",
      date: "5/3/2023",
      stars: 5.0,
      text: "Leo and his crew did a great job with our sod installation project. Very professional and hauled off all the old/dead grass.",
    },
    {
      name: "Kathleen W.",
      date: "4/30/2023",
      stars: 5.0,
      text: "Excellent",
    },
    {
      name: "Christopher L.",
      date: "4/22/2023",
      stars: 5.0,
      text: "Communication was excellent. Price - excellent. Work ethic - Leo and team works extremely hard and are skilled. They were very good at cleanup.",
    },
    {
      name: "Jeff R.",
      date: "3/31/2023",
      stars: 5.0,
      text: "It was great!",
    },
    {
      name: "Don D.",
      date: "6/8/2022",
      stars: 5.0,
      text: "Good work on my bushes and shrubs.",
    },
    {
      name: "Scott C.",
      date: "5/29/2022",
      stars: 5.0,
      text: "Very prompt and courteous.",
    },
    {
      name: "Tayler H.",
      date: "5/17/2022",
      stars: 5.0,
      text: "It's a fair price, he did really good work & I'll use him again if I ever need his services.",
    },
    {
      name: "Mannuel V.",
      date: "5/17/2022",
      stars: 5.0,
      text: "He's did a good job on my house.",
    },
  ];

  return (
    <section className="w-full py-20">
      <h2 className="text-center">Our Reviews</h2>
      <a
        href="https://www.homeadvisor.com/rated.LALandscaping.126121271.html"
        target="_blank"
        rel="noreferrer"
        className="flex w-[300px] mx-auto justify-center py-5"
      >
        <img
          alt="logoHomeAdvisor"
          src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fhomeadvisor.png?alt=media&token=5f7bbdeb-e5a8-470a-8a63-ee34d6e60661"
        />
      </a>

      {isMore
        ? reviewsChamb.map((item, index) => {
            return (
              <section
                key={index}
                className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto"
              >
                <div className="flex justify-between p-5">
                  <div className="flex">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                      alt="userphotodeatult"
                      className="w-[50px] shadow-md rounded-[50%]"
                    />
                    <div className="ml-3">
                      <span className="font-medium">{item.name}</span>
                      <Stars score={item.stars} />
                    </div>
                  </div>
                  <span className="">{item.date}</span>
                </div>
                <div className="p-5">
                  <p>{item.text}</p>
                </div>
              </section>
            );
          })
        : reviewsChamb.slice(0, 10).map((item, index) => {
            return (
              <section
                key={index}
                className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto"
              >
                <div className="flex justify-between p-5">
                  <div className="flex">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                      alt="userphotodeatult"
                      className="w-[50px] shadow-md rounded-[50%]"
                    />
                    <div className="ml-3">
                      <span className="font-medium">{item.name}</span>
                      <Stars score={item.stars} />
                    </div>
                  </div>
                  <span className="">{item.date}</span>
                </div>
                <div className="p-5">
                  <p>{item.text}</p>
                </div>
              </section>
            );
          })}

      <button
        className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white"
        onClick={handleClick}
      >
        {isMore ? "Show Less" : "Show More"}
      </button>
    </section>
  );
};

export default ChamberReviews;
